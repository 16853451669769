<template>
  <div v-if="show" @click.stop="hideDialog" class="dialog">
    <div @click.stop class="dialog__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    hideDialog() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
.dialog {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 16px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 2;
}
.dialog__content {
  min-width: 280px;
  max-width: 712px;
  max-height: 80%;
  margin: auto;
  border-radius: 16px;
  background: var(--color-bg-primary);
  overflow-y: scroll;
}
.dialog__content::-webkit-scrollbar {
  display: none;
}
.dialog__content li {
  word-break: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1023px) {
  .dialog__content {
    padding: 0 16px 16px;
  }
}


@media only screen and (min-width: 1024px) {
  .dialog__content {
    padding: 0 16px 16px
  }
}
</style>
