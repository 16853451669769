<template>
  <div class="layout">
    <h2>Transaction details</h2>
    <section class="section">
      <table class="table">
        <tbody v-if="!txLoading">
          <tr>
            <td data-label="Transaction Hash" class="hash">
              <div class="hash__item">
                <input
                   v-on:focus="$event.target.select()"
                   ref="clone"
                   readonly
                   :value="this.$route.params.hash"/>
                <button @click="copy" ref="btnToggle" class="copy-btn"><span>copy</span></button>
              </div>
              <ul v-if="txItem && txItem.error" class="list-info">
                <li>
                  <span class="label">Error</span>
                  <span class="value">{{ txItem.error.msg }}</span>
                </li>
              </ul>
              <ul v-else-if="!txItem" class="list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Emission chain id</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Emission hash</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">IN tx prev idx</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">IN tx prev hash</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">OUT Value</span>
                      <span class="value anim"></span>
                    </li>
                    <li class="w100">
                      <span class="label">OUT Address</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value anim anim-delay-2"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value anim anim-delay-3"></span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul v-else class="list-info">
                <li v-if="txItem.token" class="list-bg">
                  <ul class="list-info__list">
                    <li v-if="txItem.token.emissionChainId">
                      <span class="label">Emission chain id</span>
                      <span class="value">{{ txItem.token.emissionChainId }}</span>
                    </li>
                    <li v-if="txItem.token.emissionHash">
                      <span class="label">Emission hash</span>
                      <span class="value">{{ txItem.token.emissionHash }}</span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value">{{ txItem.token.ticker }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.in && txItem.in.length > 0" class="list-bg">
                  <ul v-for="i in txItem.in" :key="i.prevHash" class="list-info__list">
                    <li v-if="i.prevIdx || i.prevIdx == 0">
                      <span class="label">IN tx prev idx</span>
                      <span class="value">{{ i.prevIdx }}</span>
                    </li>
                    <li v-if="i.prevHash">
                      <span class="label">IN tx prev hash</span>
                      <span class="value">{{ i.prevHash }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.out && txItem.out.length > 0" class="list-bg">
                  <ul v-for="i in txItem.out" :key="i.value" class="list-info__list">
                    <li v-if="i.value">
                      <span class="label">OUT Value</span>
                      <span class="value">{{ i.value }} coins</span>
                    </li>
                    <li v-if="i.addr">
                      <span class="label">OUT Address</span>
                      <span class="value">{{ i.addr }}</span>
                    </li>
                  </ul>
                </li>
                <li v-if="txItem.sig" class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value">{{ txItem.sig.size }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value">{{ txItem.sig.sign.type }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value">{{ txItem.sig.sign.size }}</span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value">{{ txItem.sig.sign.pkeyHash }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td data-label="Transaction Hash" class="hash">
              <div class="hash__item">
                <span class="anim"></span>
              </div>
              <ul class="list-info">
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">Emission chain id</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Emission hash</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">Token ticker</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">IN tx prev idx</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">IN tx prev hash</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">OUT Value</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">OUT Address</span>
                      <span class="value anim anim-delay-1"></span>
                    </li>
                  </ul>
                </li>
                <li class="list-bg">
                  <ul class="list-info__list">
                    <li>
                      <span class="label">SIG size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE type</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE size</span>
                      <span class="value anim"></span>
                    </li>
                    <li>
                      <span class="label">SIGNATURE fingerprint</span>
                      <span class="value anim"></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  data() {
    return {
      hintVisible: false,
      hintText: 'Error',

      txItem: '',

      inputTxValue: '',

      selectedTxNet: '',

      txLoading: false,

      text: 'This will get copied!',

      monthNames: [
        'Jan','Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],

      txUrl: '',
      baseUrlMinkowski: 'https://engine-minkowski.kelvpn.com/expl',
    }
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 100
    }
  },
  methods: {
    async searchTxItem(hash) {
      try {
        setTimeout(async() => {
          const response = await axios.get(this.txUrl, {
            params: {
              method: 'tx',
              net: this.selectedTxNet,
              hash: hash
            }
          });
          // let needle_index = this.$refs['hash-ref-'+hash];

          // this.dagDetails.set(hash, response.data);

          console.log(response.data)
          this.txItem = response.data;
          // if (response.data) {
          //   this.txDetails.set(hash, response.data);
          //   this.dialogTxValue = hash
          //   this.showTxDialog()
          // }
        }, 1000)
      } catch(error) {
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    copy() {
      // console.log(this.$route.fullPath)
      // var Url = this.$route.fullPath;
      // Url.innerHTML = window.location.href;
      // Url.select();
      // console.log(Url.innerHTML)
      this.$refs.btnToggle.innerText = 'copy';
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$refs.btnToggle.innerText = 'done';
      setTimeout( async() => {
        this.$refs.btnToggle.innerText = 'copy';
      }, 800);
    },

    showHint() {
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000)
    },
  },

  mounted() {
    this.selectedTxNet = this.$route.params.net
    if (this.$route.params.url) {
      this.txUrl = this.$route.params.url
    } else if (this.selectedTxNet == 'kelvpn-minkowski') {
      this.txUrl = this.baseUrlMinkowski
    }
    this.searchTxItem(this.$route.params.hash);
  },

  watch: {
  }
}
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}
.layout h2 {
  text-align: center;
}

.section {
  max-width: 1080px;
}
.section--option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

tbody tr:first-child {
  margin: 0;
}

.hash__item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
}

td input {
  outline: none;
  font-family: inherit;
  font-size: 100%;
  width: 80%;
  margin: 0;
  padding: 0;
  border: none;
  color: var(--color-text-primary);
  background: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
td button {
  font-family: inherit;
  /* font-size: 12px; */
  position: absolute;
  right: 0;
  width: 44px;
  margin: 0;
  padding: 4px;
  border: none;
  border-radius: 4px;
  color: var(--color-text-primary);
  background-color: var(--color-bg-secondary);
  cursor: pointer;
}
td button:hover {
  color: var(--color-primary);
  background-color: var(--color-accent);
}
</style>
