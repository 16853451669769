<template>
  <table class="table is-bordered">
    <!-- <thead>
      <tr>
        <th draggable="false" class="">
          <div class="th-wrap">
            <span class="is-relative">Timestamp</span>
          </div>
        </th>
        <th draggable="false" class="">
          <div class="th-wrap">
            <span class="is-relative">Hash</span>
          </div>
        </th>
      </tr>
    </thead> -->
    <tbody>
      <tr v-for="item in items" :key="item.hash" draggable="false">
        <td data-label="Timestamp" class="timestamp">
          {{ item.date.time }} {{ item.date.day }} {{ item.date.month }} {{ item.date.year }}
        </td>
        <td data-label="Hash" class="hash" @click="getInfo(item.hash)">
          {{ item.hash }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Table',
  props: {
    items: {
      type: Array
    }
  },

  methods: {
    async getInfo(hash) {
      try {
        const response = await axios.get('http://85.209.0.191:8079/expl', {
            params: {
              method: 'dag',
              net: 'kelvin-testnet',
              chain: 'zerochain',
              hash: hash
            }
          },
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
          console.log(response.data)
      } catch(e) {
        // alert('Ошибка')
        console.log('Ошибка')
      }
    },
  }
}
</script>

<style scoped>
table {
  width: 100%;
  margin: 0 0 16px;
  padding: 16px;
  background: var(--color-bg-primary);
  border: none;
  border-radius: 16px;
  box-shadow: var(--color-shadow);
}

tr th {
  text-align: left;
  color: var(--color-text-secondary);
}

tbody tr {
  display: flex;
  gap: 32px;
}
tbody tr:not(:last-child) {
  margin: 16px 0;
}
tbody tr:first-child {
  margin: 0 0 16px;
}

td {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}
.timestamp {
  white-space: nowrap;
}
.hash {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  cursor: pointer;
}
.hash:hover {
  color: var(--color-accent);
}
td:before {
  content: attr(data-label);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: var(--color-text-secondary);
}


@media screen and (max-width: 767.7px) {
  tbody tr {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .timestamp {
    flex-basis: 200px;
  }
}
</style>
