<template>
  <label class="select neumorphism" for="slct">
    <select :value="modelValue" @change="changeOption" id="slct" required="required">
      <option value="" disabled="disabled" selected="selected">Select option</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
    </select>
    <svg>
      <use xlink:href="#select-arrow-down"></use>
    </svg>
  </label>
  <!-- SVG Sprites-->
  <svg class="sprites">
    <symbol id="select-arrow-down" viewbox="0 0 10 6">
      <polyline points="1 1 5 5 9 1"></polyline>
    </symbol>
  </svg>
</template>

<script>
export default {
  name: 'Select',

  props: {
    modelValue: [String],
    options: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    changeOption(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped>
.select {
  position: relative;
  width: fit-content;
}
.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  transform: rotate(90deg);
  stroke-width: 2px;
  stroke: var(--color-text-primary);
  fill: none;

  -webkit-transition: transform ease-in-out .1s;
  -moz-transition: transform ease-in-out .1s;
  -ms-transition: transform ease-in-out .1s;
  -o-transition: transform ease-in-out .1s;
  transition: transform ease-in-out .1s;
}
.select select {
  -webkit-appearance: none;
  outline: none;
  font-size: 16px;
  margin: 0;
  padding: 12px 40px 12px 16px;
  color: var(--color-text-primary);
  background-color: var(--color-bg-primary);
  border: none;
  border-radius: 16px;
  box-shadow: var(--color-shadow);
  cursor: pointer;

  -webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}
.select select:required:invalid {
  color: #5a667f;
}
.select select option[value=""][disabled] {
  display: none;
}
.select select:focus + svg {
  transform: rotate(0);
}
.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}


@media screen and (max-width: 767.7px) {
  .select,
  select {
    width: 100%;
  }
}
</style>
