<template>
  <transition name="slide-fade">
    <div v-if="show" @click.stop="hideHint" class="hint">
      <div @click.stop class="hint__content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Hint',

  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    hideHint() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
.hint {
  position: fixed;
  top: 16px;
  right: 0;
  left: 0;
  padding: 0 16px;
  z-index: 99999;
}
.hint__content {
  text-align: center;
  min-width: 280px;
  max-width: 680px;
  max-height: 80%;
  margin: auto;
  padding: 12px 16px;
  border-radius: 16px;
  background: rgba(232, 6, 83, .5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.hint__content p {
  line-height: initial;
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


@media only screen and (max-width: 1023px) {
  .hint__content p {
    font-size: 18px;
  }
}


@media only screen and (min-width: 1024px) {
  .hint__content p {
    font-size: 20px;
  }
}
</style>
