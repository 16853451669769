<template>
  <header :class="{open: isActive}" class="header">
    <div class="header__container">
      <div class="header__logo">
        <a href="/"><img src="@/assets/logo.svg" alt="logo"></a>
      </div>
      <div class="header__body">
        <nav class="header__nav">
          <ul class="header__list">
            <li class="header__item">
							<router-link to="/transaction" @click="navShow()" class="header__link neumorphism neumorphism--button">Transaction explorer</router-link>
            </li>
            <li class="header__item">
							<router-link to="/event" @click="navShow()" class="header__link neumorphism neumorphism--button">Chain explorer</router-link>
            </li>
          </ul>
        </nav>
				<div class="header__toggle" @click="navShow()">
					<div class="header__toggle-wrap">
						<div class="header__hamburger">
							<div class="header__line"></div>
							<div class="header__line"></div>
							<div class="header__line"></div>
						</div>
					</div>
				</div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Nav',

	data() {
    return {
      isActive: false
    }
  },

	methods: {
		navShow() {
			this.isActive = !this.isActive;
		},
  },
}
</script>

<style scoped>
/*---------------------------------------------------*/
/*	TOPBAR
/*---------------------------------------------------*/

.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  padding: 16px;
  gap: 32px;
  z-index: 9;

  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

.header__logo a {
  display: flex;
}

.header__logo img {
  max-width: 112px;
  max-height: 70px;
}

.header__body {
  display: flex;
  align-items: center;
}

.header__nav {
  z-index: 1;

  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}
.header__list {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 32px;
}

.header__item {
  position: relative;

  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.header__item.opened {
  opacity: 1;
  visibility: visible;
  left: 0;
}
.header__link {
  text-decoration: none;
  text-align: center;
  padding: 12px 30px;
  color: var(--color-text-primary);
  background-color: var(--color-bg-primary);
  cursor: pointer;
}
/* .header__link:hover {
  color: var(--color-primary);
  background: transparent linear-gradient(260deg, #E3FF59 0%, #D2FF77 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71;
} */
.router-link-active {
  color: var(--color-accent);
  -webkit-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  -moz-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  -o-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  cursor: default;
}

.header__toggle {
  cursor: pointer;
}
.header__toggle-wrap {
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__hamburger {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 26px;
  height: 20px;
}
.header__line {
  height: 3px;
  background-color: var(--color-text-primary);
  border-radius: 2px;
  z-index: 9999!important;
}

.open .header__hamburger {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  }
.open .header__line {
  display: none;
}
.open .header__line:first-child {
  display: initial;
  position: relative;
  top: 8.5px;
}
.open .header__line:last-child {
  display: initial;
  position: relative;
  top: -8.5px;

  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media only screen and (max-width: 767.7px) {
  .header__logo img {
    width: auto;
    height: 24px;
  }

  .open .header__container {
    background-color: var(--color-primary);
  }

  .open .header__nav {
    display: initial;
    opacity: 1;

    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .header__nav {
    position: absolute;
    display: flex;
    top: 66px;
    right: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    background-color: var(--color-bg-primary);
    opacity: 0;

    -webkit-transform: translateY(calc(-100% - 68px));
    -moz-transform: translateY(calc(-100% - 68px));
    -ms-transform: translateY(calc(-100% - 68px));
    -o-transform: translateY(calc(-100% - 68px));
    transform: translateY(calc(-100% - 68px));
    z-index: 9999;
  }
  .header__list {
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }
  .header__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
  }
  .header__link {
    width: 100%;
    justify-content: center;
    border: 0!important;
  }
}


@media only screen and (min-width: 768px) {
  .header__logo img {
    width: auto;
    height: 30px;
  }

  .header__toggle {
    display: none;
  }
}
</style>
