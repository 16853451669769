<template>
  <label class="search">
    <input :value="modelValue" @input="updateInput" type="text" placeholder="0xB7FF8447A3C5FC3AEED3F724984985D075E48E3E2FBB0A4C8847B52AD4A2B883">
  </label>
</template>

<script>
export default {
  name: 'InputSearch',

  props: {
    modelValue: [String, Number],
  },

  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style>
.search {
  position: relative;
  min-width: 228px;
  outline: none;
}
.search:before {
  content: "";
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  width: 16px;
  background: url('/img/search_icon.svg') center / contain no-repeat;
  z-index: 1;
}
.search input {
  -webkit-appearance: none;
  outline: none;
  font-size: 16px;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 12px 48px 12px 16px;
  color: var(--color-text-primary);
  background: var(--color-bg-primary);
  border: none;
  border-radius: 16px;
  box-shadow: var(--color-shadow);
  cursor: auto;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}
.search:active,
.search:focus,
.search:hover,
.search:active input,
.search:focus input,
.search:hover input,
.search input:active,
.search input:focus,
.search input:hover {
  width: 100%!important;
}
</style>
