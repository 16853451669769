<template>
  <div class="layout">
    <h2>Transaction explorer</h2>
    <section class="section section--option">
      <Select v-model="selectedTxNet" :options="netTxOptions "/>
      <span>network history contains <b>{{ txListCountAnim }}</b> transactions</span>
    </section>
    <section class="section">
      <Dialog v-model:show="dialogTxVisible">
        <ul v-if="txDetails.get(this.dialogTxValue) && txDetails.get(this.dialogTxValue).error" class="list-info">
          <li>
            <span class="label">Error</span>
            <span class="value">{{ txDetails.get(this.dialogTxValue).error }}</span>
          </li>
        </ul>
        <ul v-else-if="!txDetails.get(this.dialogTxValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li class="w100">
            <span class="label">Transaction hash</span>
            <div class="hash__item">
              <router-link :to="{ name: 'TransactionDetails', params: { hash: txDetails.get(this.dialogTxValue).hash, net:this.selectedTxNet, url: this.txUrl }}" class="value value--action text-overflow">
                <span>{{ txDetails.get(this.dialogTxValue).hash }}</span>
              </router-link>
              <router-link :to="{ name: 'TransactionDetails', params: { hash: txDetails.get(this.dialogTxValue).hash, net:this.selectedTxNet, url: this.txUrl }}" class="value value--action link--arrow">
                <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
              </router-link>
            </div>
          </li>
          <li v-if="txDetails.get(this.dialogTxValue).token.emissionHash" class="list-bg">
            <ul class="list-info__list">
              <li>
                <span class="label">Emission chain id</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).token.emissionChainId }}</span>
              </li>
              <li>
                <span class="label">Emission hash</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).token.emissionHash }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).in" :key="i.prevHash" class="list-info__list">
              <li v-if="i.prevIdx || i.prevIdx == 0">
                <span class="label">IN tx prev idx</span>
                <span class="value">{{ i.prevIdx }}</span>
              </li>
              <li v-if="i.prevHash">
                <span class="label">IN tx prev hash</span>
                <span class="value">{{ i.prevHash }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).out" :key="i.value" class="list-info__list">
              <li v-if="i.value">
                <span class="label">OUT Value</span>
                <span class="value">{{ i.value }} coins</span>
              </li>
              <li v-if="i.addr">
                <span class="label">OUT Address</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-if="txDetails.get(this.dialogTxValue).sig" class="list-info__list">
              <li>
                <span class="label">SIG size</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.size }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE type</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.type }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE size</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.size }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE fingerprint</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.pkeyHash }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <div class="section__meta">
        <InputSearch v-model="inputTxValue" />
        <Select v-model="selectedSortTxItem" :options="sortTxOptions" />
      </div>
      <table class="table">
        <tbody v-if="!txLoading">
          <tr v-for="item in txList" :key="item.hash">
            <td data-label="Timestamp" class="timestamp">
              {{ item.dateTime.time }} {{ item.dateTime.day }} {{ monthNames[item.dateTime.month - 1] }} {{ item.dateTime.year }}
            </td>
            <td v-if="item.token" data-label="Token ticker" class="ticker">
              {{ item.token.ticker }}
            </td>
             <td v-else data-label="Token ticker" class="ticker">
              ---------
            </td>
            <td data-label="Hash" class="hash">
              <details>
                <summary @click="getTxInfo(item.hash)">
                  <div class="hash__item">
                    <span>{{ item.hash }}</span>
                    <router-link :to="{ name: 'TransactionDetails', params: { hash: item.hash, net:this.selectedTxNet, url: this.txUrl }}" class="value value--action link--arrow">
                      <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                    </router-link>
                  </div>
                </summary>
                <ul v-if="txDetails.get(item.hash) && txDetails.get(item.hash).error" :ref="'hash-ref-'+item.hash" class="list-info">
                  <li>
                    <span class="label">Error</span>
                    <span class="value">{{ txDetails.get(item.hash).error.msg }}</span>
                  </li>
                </ul>
                <ul v-else-if="txDetails.get(item.hash)" class="list-info">
                  <li v-if="txDetails.get(item.hash).token.emissionHash" class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">Emission chain id</span>
                        <span class="value">{{ txDetails.get(item.hash).token.emissionChainId }}</span>
                      </li>
                      <li>
                        <span class="label">Emission hash</span>
                        <span class="value">{{ txDetails.get(item.hash).token.emissionHash }}</span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul v-for="i in txDetails.get(item.hash).in" :key="i.prevHash" class="list-info__list">
                      <li v-if="i.prevIdx || i.prevIdx == 0">
                        <span class="label">IN tx prev idx</span>
                        <span class="value">{{ i.prevIdx }}</span>
                      </li>
                      <li v-if="i.prevHash">
                        <span class="label">IN tx prev hash</span>
                        <span class="value">{{ i.prevHash }}</span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul v-for="i in txDetails.get(item.hash).out" :key="i.value" class="list-info__list">
                      <li v-if="i.value">
                        <span class="label">OUT Value</span>
                        <span class="value">{{ i.value }} coins</span>
                      </li>
                      <li v-if="i.addr">
                        <span class="label">OUT Address</span>
                        <span class="value">{{ i.addr }}</span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul v-if="txDetails.get(item.hash).sig" class="list-info__list">
                      <li>
                        <span class="label">SIG size</span>
                        <span class="value">{{ txDetails.get(item.hash).sig.size }}</span>
                      </li>
                      <li>
                        <span class="label">SIGNATURE type</span>
                        <span class="value">{{ txDetails.get(item.hash).sig.sign.type }}</span>
                      </li>
                      <li>
                        <span class="label">SIGNATURE size</span>
                        <span class="value">{{ txDetails.get(item.hash).sig.sign.size }}</span>
                      </li>
                      <li>
                        <span class="label">SIGNATURE fingerprint</span>
                        <span class="value">{{ txDetails.get(item.hash).sig.sign.pkeyHash }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul v-else :ref="'hash-ref-'+item.hash" class="list-info">
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">Emission chain id</span>
                        <span class="value anim"></span>
                      </li>
                      <li class="w100">
                        <span class="label">Emission hash</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">IN tx prev idx</span>
                        <span class="value anim"></span>
                      </li>
                      <li class="w100">
                        <span class="label">IN tx prev hash</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">OUT Value</span>
                        <span class="value anim"></span>
                      </li>
                      <li class="w100">
                        <span class="label">OUT Address</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">SIG size</span>
                        <span class="value anim"></span>
                      </li>
                      <li>
                        <span class="label">SIGNATURE type</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                      <li>
                        <span class="label">SIGNATURE size</span>
                        <span class="value anim anim-delay-2"></span>
                      </li>
                      <li class="w100">
                        <span class="label">SIGNATURE fingerprint</span>
                        <span class="value anim"></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </details>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="item in size" :key="item">
            <td data-label="Timestamp" class="timestamp"><span class="anim"></span></td>
            <td data-label="Token ticker" class="ticker"><span class="anim anim-delay-1"></span></td>
            <td data-label="Hash" class="hash"><span class="anim anim-delay-2"></span></td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevTxPage" :disabled="txPage.cur === 1" class="pagination__prev"></button>
        <span>Showing {{ txPage.startItem }} to <span v-if="txPage.endItem >= txListCount">{{ txListCount }}</span><span v-else>{{ txPage.endItem }}</span> of {{ txListCount }} entries</span>
        <button @click="nextTxPage" :disabled="txPage.cur >= txPage.total" class="pagination__next"></button>
      </div>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  data() {
    return {
      hintVisible: false,
      hintText: 'Error',

      txLoading: false,

      txList: [],

      txListCount: 0,
      txListCountAnim: 0,

      dialogTxVisible: false,
      dialogTxValue: '',

      inputTxValue: '',

      selectedTxNet: 'kelvpn-minkowski',
      netTxOptions: [
        { value: 'kelvpn-minkowski', name: 'kelvpn-minkowski'},
      ],

      txDetails: new Map(),

      txPage: {
        total: 0,
        cur: 1,
        pageLimit: 10,
        startItem: 1,
        endItem: 1,
        itemCount: 100,
      },

      monthNames: [
        'Jan','Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],

      selectedSortTxItem: 'new',
      sortTxOptions: [
        { value: 'new', name: 'first new'},
        { value: 'old', name: 'old ones first'}
      ],

      baseUrlMinkowski: 'https://engine-minkowski.kelvpn.com/expl',
      txUrl: 'https://engine-minkowski.kelvpn.com/expl',
    }
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 100
    }
  },
  methods: {
    txAnimateCount(animationDuration) {
      const frameDuration = 1000 / 60;
      const totalFrames = Math.round( animationDuration / frameDuration );
      const easeOutQuad = t => 1 - Math.pow(1 - t, 5);

      let frame = 0;
      const countTo = this.txListCount;

      const counter = setInterval( () => {
        frame++;

        const progress = easeOutQuad( frame / totalFrames );

        const currentCount = Math.round( countTo * progress );

        if ( this.txListCountAnim !== currentCount ) {
          this.txListCountAnim = currentCount;
        }

        if ( frame === totalFrames ) {
          clearInterval( counter );
        }
      }, frameDuration );
    },

    async txItemsCount() {
      try {
        this.txLoading = true;

        const response = await axios.get(this.txUrl, {
          params: {
            method: 'txListCount',
            net: this.selectedTxNet,
          }
        });

        this.txListCount = response.data.countTx;

        if (response.data.countTx <= this.size) {
          this.txPage.itemCount = response.data.countTx;
        } else {
          this.txPage.itemCount = this.size
        }

        this.txAnimateCount(2000)

        console.log(response.data)

        this.txItemsLimited()

        this.txPageCount();
        this.txPaginatedData();
      } catch(error) {
        this.txLoading = true;
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    async txItemsLimited() {
      try {
        let params = {
          method: 'txListLimited',
          net: this.selectedTxNet,
          count: this.txPage.itemCount,
          page: this.txPage.cur,
        }
        if (this.selectedSortTxItem == 'old') params.reverse = 'old';

        setTimeout( async() => {
          const response = await axios.get(this.txUrl, {
            params: params,
            // headers: {
            //   'Accept': 'application/json',
            //   'Content-Type': 'application/json',
            //   'Origin': ''
            // }
          });

          console.log(response)
          this.txList = response.data.transactions.transactions;
          this.txLoading = false;
        }, 1000)
      } catch(error) {
        this.txLoading = true;
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    async getTxInfo(hash) {
      if (!this.txDetails.has(hash)) {
        try {
          setTimeout( async() => {
            const response = await axios.get(this.txUrl, {
              params: {
                method: 'tx',
                net: this.selectedTxNet,
                // chain: this.selectedChain,
                // hash: hash.replace(/\s+/g, ' ').replace('%00', ' ').trim()
                hash: hash
              }
            });

            console.log(response)
            this.txDetails.set(hash, response.data);
          }, 1000)
        } catch(error) {
          console.log(error.message)
          this.hintText = error.message
          this.showHint()
        }
      }
    },

    async searchTxItem(hash, net, url) {
      try {
        const response = await axios.get(url, {
          params: {
            method: 'tx',
            net: net,
            hash: hash
          }
        });
        // let needle_index = this.$refs['hash-ref-'+hash];

        // this.dagDetails.set(hash, response.data);

        console.log(response.data)
        if (response.data) {
          this.txDetails.set(hash, response.data);
          this.dialogTxValue = hash
          this.showTxDialog()
        }
      } catch(error) {
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    showTxDialog() {
      this.dialogTxVisible = true;
    },

    nextTxPage() {
      this.txPage.cur++;
      if (this.selectedSortTxItem == 'new') {
        this.txItemsCount();
        this.txPaginatedData();
      } else if (this.selectedSortTxItem == 'old') {
        this.txItemsCount();
        this.txPaginatedData();
      }
    },
    prevTxPage() {
      this.txPage.cur--;
      if (this.selectedSortTxItem == 'new') {
        this.txItemsCount();
        this.txPaginatedData();
      } else if (this.selectedSortTxItem == 'old') {
        this.txItemsCount();
        this.txPaginatedData();
      }
    },
    txPageCount() {
      let l = this.txListCount,
          s = this.txPage.itemCount;
      this.txPage.total = Math.ceil(l/s);
      return this.txPage.total
    },
    txPaginatedData() {
      const start = Number((this.txPage.cur - 1) * this.txPage.itemCount),
            end = Number(start + this.txPage.itemCount);
      this.txPage.startItem = start + 1;
      this.txPage.endItem = end;
    },

    showHint() {
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000)
    },
  },

  mounted() {
    this.txItemsCount();
  },

  watch: {
    selectedTxNet() {
      this.txPage.cur = 1
      this.txItemsCount();
    },
    inputTxValue() {
      if (this.txDetails.has(this.inputTxValue)) {
        this.dialogTxValue = this.inputTxValue
        this.showTxDialog()
      } else if (this.inputTxValue.length > 60) {
        this.searchTxItem(this.inputTxValue, this.selectedTxNet, this.txUrl)
      }
    },
    selectedSortTxItem() {
      this.txPage.cur = 1
      if (this.selectedSortTxItem == 'new') {
        this.txItemsCount();
      } else if (this.selectedSortTxItem == 'old') {
        this.txItemsCount();
      }
    }
  }
}
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}


@media screen and (min-width: 768px) {
  .layout h2 {
    display: none;
  }
}
</style>
