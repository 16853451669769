<template>
  <Nav/>
  <div class="spacer"></div>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
import Nav from './components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav
  },
  methods: {
    redirectToExplorerCellframe() {
      window.location.href = 'https://explorer.cellframe.net/'
    },
  },
  created() {
    this.redirectToExplorerCellframe()
  }
}
</script>

<style>
*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }

html {
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  height: 100%;
  margin: 0;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  --color-primary: #F7F8FA;
  --color-secondary: #220E34;
  --color-text-primary: #04004E;
  --color-text-secondary: #03003c;
  --color-bg-primary: #F7F8FA;
  --color-bg-secondary: rgba(232, 6, 83, 0.03);
  --color-shadow: 2px 2px 9px -1px rgba(174, 174, 192, .38), -1px -1px 12px 1px #fff, inset 2px 2px 6px 0px #fff;
  --color-accent: #e80653;
  --color-allert: #e80653;
}

.content {
  position: relative;
  min-height: 100%;
  color: var(--color-text-primary);
  background-color: var(--color-primary);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  width: 100%;
}

.text-overflow {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}
.flex--col {
  flex-direction: column;
}
.flex--center {
  align-items: center;
}
.flex-jc-c {
	justify-content: center;
}
.flex-jc-sb {
	justify-content: space-between;
}
.flex-jc-end {
	justify-content: flex-end;
}

.neumorphism {
  background-color: var(--color-body-bg);
  border-radius: 16px;
  overflow: hidden;
  -webkit-box-shadow: 2px 2px 9px -1px rgba(174, 174, 192, .38), -1px -1px 12px 1px #fff, inset 2px 2px 6px 0px #fff;
  -moz-box-shadow: 2px 2px 9px -1px rgba(174, 174, 192, .38), -1px -1px 12px 1px #fff, inset 2px 2px 6px 0px #fff;
  -o-box-shadow: 2px 2px 9px -1px rgba(174, 174, 192, .38), -1px -1px 12px 1px #fff, inset 2px 2px 6px 0px #fff;
  box-shadow: 2px 2px 9px -1px rgba(174, 174, 192, .38), -1px -1px 12px 1px #fff, inset 2px 2px 6px 0px #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.neumorphism--button:hover {
  color: var(--color-accent);
  -webkit-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  -moz-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  -o-box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
  box-shadow: inset 2px 2px 2px 0px rgba(174, 174, 192, .25), 2px 2px 5px 0px #fff;
}


@media only screen and (max-width: 1023px) {
  body {
    font-size: 16px;
  }

  .content {
    background-image: url(/img/bg/bg_desktop.svg);
  }

  @media only screen and (max-width: 767.7px) {
    h1 { font-size: 40px; }
    h2 { font-size: 30px; }
    h3 { font-size: 24px; }
    h4 { font-size: 20px; }

    .content {
      background-image: url(/img/bg/bg_mobile.svg);
    }
  }
  @media only screen and (min-width: 768px) {
    h1 { font-size: 42px; }
    h2 { font-size: 34px; }
    h3 { font-size: 28px; }
    h4 { font-size: 22px; }
  }
}


@media only screen and (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  .content {
    background-image: url(/img/bg/bg_desktop.svg);
  }

  @media only screen and (max-width: 1279px) {
    h1 { font-size: 42px; }
    h2 { font-size: 34px; }
    h3 { font-size: 28px; }
    h4 { font-size: 22px; }
  }
  @media only screen and (min-width: 1280px) {
    h1 { font-size: 45px; }
    h2 { font-size: 36px; }
    h3 { font-size: 30px; }
    h4 { font-size: 24px; }
  }
}
</style>
