<template>
  <div class="layout">
    <h2>Chain explorer</h2>
    <section class="section section--option">
      <Select v-model="selectedDagNet" :options="netDagOptions "/>
      <span>network history contains <b>{{ dagListCountAnim }}</b></span>
      <Select v-model="selectedDagChain" :options="chainDagOptions "/>
      <span>chain events</span>
    </section>
    <section class="section">
      <Dialog v-model:show="dialogDagVisible">
        <ul v-if="dagDetails.get(this.dialogDagValue) && dagDetails.get(this.dialogDagValue).error" class="list-info">
          <li>
            <span class="label">Error</span>
            <span class="value">{{ dagDetails.get(this.dialogDagValue).error.msg }}</span>
          </li>
        </ul>
        <ul v-else-if="!dagDetails.get(this.dialogDagValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li>
            <span class="label">Event</span>
            <span class="value">{{ this.dialogDagValue }}</span>
          </li>
          <li class="list-bg">
            <ul class="list-info__list">
              <li v-if="dagDetails.get(this.dialogDagValue).version || dagDetails.get(this.dialogDagValue).version == 0">
                <span class="label">Version</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).version }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).round_id || dagDetails.get(this.dialogDagValue).round_id == 0">
                <span class="label">Round ID</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).round_id }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).cell_id">
                <span class="label">Cell ID</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).cell_id }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).chain_id">
                <span class="label">Chain ID</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).chain_id }}</span>
              </li>
            </ul>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).datum" class="list-bg">
            <ul class="list-info__list">
              <li v-if="dagDetails.get(this.dialogDagValue).datum.version">
                <span class="label">Datum version</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.version }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.type">
                <span class="label">Datum type</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.type }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.created">
                <span class="label">Datum timestamp</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.created.time }} {{ dagDetails.get(this.dialogDagValue).datum.created.day }} {{ monthNames[dagDetails.get(this.dialogDagValue).datum.created.month - 1] }} {{ dagDetails.get(this.dialogDagValue).datum.created.year }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.size">
                <span class="label">Datum size</span>
                <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.size }}</span>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.data.tx" style="width: 100%;">
                <span class="label">Transaction hash</span>
                <div class="hash__item">
                  <router-link :to="{ name: 'TransactionDetails', params: { hash: dagDetails.get(this.dialogDagValue).datum.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                    <span>{{ dagDetails.get(this.dialogDagValue).datum.data.tx.hash }}</span>
                  </router-link>
                  <router-link :to="{ name: 'TransactionDetails', params: { hash: dagDetails.get(this.dialogDagValue).datum.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                    <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                  </router-link>
                </div>
              </li>
              <li v-if="dagDetails.get(this.dialogDagValue).datum.data.token_emission" class="list-bg">
                <ul class="list-info__list">
                  <li>
                    <span class="label">Token emission hash</span>
                    <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.data.token_emission.addr }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission version</span>
                    <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.data.token_emission.version }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission value</span>
                    <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.data.token_emission.value }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission ticker</span>
                    <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.data.token_emission.ticker }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission type</span>
                    <span class="value">{{ dagDetails.get(this.dialogDagValue).datum.data.token_emission.type }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li v-else-if="dagDetails.get(this.dialogDagValue).datums" class="list-bg">
            <ul v-for="item in dagDetails.get(this.dialogDagValue).datums" :key="item.data.tx.hash" class="list-info__list">
              <li v-if="item.version">
                <span class="label">Datum version</span>
                <span class="value">{{ item.version }}</span>
              </li>
              <li v-if="item.type">
                <span class="label">Datum type</span>
                <span class="value">{{ item.type }}</span>
              </li>
              <li v-if="item.created">
                <span class="label">Datum timestamp</span>
                <span class="value">{{ item.created.time }} {{ item.created.day }} {{ monthNames[item.created.month - 1] }} {{ item.created.year }}</span>
              </li>
              <li v-if="item.size">
                <span class="label">Datum size</span>
                <span class="value">{{ item.size }}</span>
              </li>
              <li v-if="item.data.tx" class="w100">
                <span class="label">Transaction hash</span>
                <div class="hash__item">
                  <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                    <span>{{ item.data.tx.hash }}</span>
                  </router-link>
                  <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                    <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                  </router-link>
                </div>
              </li>
              <li v-if="item.data.token_emission" class="list-bg">
                <ul class="list-info__list">
                  <li>
                    <span class="label">Token emission hash</span>
                    <span class="value">{{ item.data.token_emission.addr }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission version</span>
                    <span class="value">{{ item.data.token_emission.version }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission value</span>
                    <span class="value">{{ item.data.token_emission.value }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission ticker</span>
                    <span class="value">{{ item.data.token_emission.ticker }}</span>
                  </li>
                  <li>
                    <span class="label">Token emission type</span>
                    <span class="value">{{ item.data.token_emission.type }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).data_size">
            <span class="label">Data size</span>
            <span class="value">{{ dagDetails.get(this.dialogDagValue).data_size }}</span>
          </li>
          <li v-if="dagDetails.get(this.dialogDagValue).signs" class="list-bg">
            <ul v-for="i in dagDetails.get(this.dialogDagValue).signs" :key="i.type" class="list-info__list">
              <li>
                <span class="label">Signs type</span>
                <span class="value">{{ i.type }}</span>
              </li>
              <li>
                <span class="label">Signs size</span>
                <span class="value">{{ i.size }}</span>
              </li>
              <li v-if="i.pkey.size || i.pkey.size == 0">
                <span class="label">Signs pkey size</span>
                <span class="value">{{ i.pkey.size }}</span>
              </li>
              <li>
                <span class="label">Signs pkey type</span>
                <span class="value">{{ i.pkey.type }}</span>
              </li>
              <li>
                <span class="label">Signs pkey hash</span>
                <span class="value">{{ i.pkey_hash }}</span>
              </li>
              <li>
                <span class="label">Signs fingerprint</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <Dialog v-model:show="dialogTxVisible">
        <ul v-if="txDetails.get(this.dialogTxValue) && txDetails.get(this.dialogTxValue).error" class="list-info">
          <li>
            <span class="label">Error</span>
            <span class="value">{{ txDetails.get(this.dialogTxValue).error }}</span>
          </li>
        </ul>
        <ul v-else-if="!txDetails.get(this.dialogTxValue)" class="list-info">
          <li>
            <span class="value">Loading...</span>
          </li>
        </ul>
        <ul v-else class="list-info">
          <li class="w100">
            <span class="label">Transaction hash</span>
            <div class="hash__item">
              <router-link :to="{ name: 'TransactionDetails', params: { hash: txDetails.get(this.dialogTxValue).hash, net:this.selectedDagNet, url: this.dagUrl }}" class="value value--action text-overflow">
                <span>{{ txDetails.get(this.dialogTxValue).hash }}</span>
              </router-link>
              <router-link :to="{ name: 'TransactionDetails', params: { hash: txDetails.get(this.dialogTxValue).hash, net:this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
              </router-link>
            </div>
          </li>
          <li v-if="txDetails.get(this.dialogTxValue).token.emissionHash" class="list-bg">
            <ul class="list-info__list">
              <li>
                <span class="label">Emission chain id</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).token.emissionChainId }}</span>
              </li>
              <li>
                <span class="label">Emission hash</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).token.emissionHash }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).in" :key="i.prevHash" class="list-info__list">
              <li v-if="i.prevIdx || i.prevIdx == 0">
                <span class="label">IN tx prev idx</span>
                <span class="value">{{ i.prevIdx }}</span>
              </li>
              <li v-if="i.prevHash">
                <span class="label">IN tx prev hash</span>
                <span class="value">{{ i.prevHash }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-for="i in txDetails.get(this.dialogTxValue).out" :key="i.value" class="list-info__list">
              <li v-if="i.value">
                <span class="label">OUT Value</span>
                <span class="value">{{ i.value }} coins</span>
              </li>
              <li v-if="i.addr">
                <span class="label">OUT Address</span>
                <span class="value">{{ i.addr }}</span>
              </li>
            </ul>
          </li>
          <li class="list-bg">
            <ul v-if="txDetails.get(this.dialogTxValue).sig" class="list-info__list">
              <li>
                <span class="label">SIG size</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.size }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE type</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.type }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE size</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.size }}</span>
              </li>
              <li>
                <span class="label">SIGNATURE fingerprint</span>
                <span class="value">{{ txDetails.get(this.dialogTxValue).sig.sign.pkeyHash }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </Dialog>
      <div class="section__meta">
        <InputSearch v-model="inputDagValue" />
        <Select v-model="selectedSortDagItem" :options="sortDagOptions" />
      </div>
      <table class="table">
        <tbody v-if="!dagLoading">
          <tr v-for="item in dagList" :key="item.hash">
            <td data-label="Timestamp" class="timestamp">
              {{ item.date.time }} {{ item.date.day }} {{ monthNames[item.date.month - 1] }} {{ item.date.year }}
            </td>
            <td data-label="Hash" class="hash">
              <details>
                <summary @click="getDagInfo(item.hash)">
                  <span>{{ item.hash }}</span>
                </summary>
                <ul v-if="dagDetails.get(item.hash) && dagDetails.get(item.hash).error" class="list-info">
                  <li>
                    <span class="label">Error</span>
                    <span class="value">{{ dagDetails.get(item.hash).error.msg }}</span>
                  </li>
                </ul>
                <ul v-else-if="!dagDetails.get(item.hash)" :ref="'hash-ref-'+item.hash" class="list-info">
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">Version</span>
                        <span class="value anim"></span>
                      </li>
                      <li>
                        <span class="label">Round ID</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                      <li>
                        <span class="label">Cell ID</span>
                        <span class="value anim anim-delay-2"></span>
                      </li>
                      <li>
                        <span class="label">Chain ID</span>
                        <span class="value anim anim-delay-3"></span>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">Datum version</span>
                        <span class="value anim"></span>
                      </li>
                      <li>
                        <span class="label">Datum type</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                      <li>
                        <span class="label">Datum timestamp</span>
                        <span class="value anim anim-delay-2"></span>
                      </li>
                      <li>
                        <span class="label">Datum size</span>
                        <span class="value anim anim-delay-3"></span>
                      </li>
                      <li class="w100">
                        <span class="label">Transaction hash</span>
                        <div class="hash__item">
                          <span class="value value--action anim"></span>
                        </div>
                      </li>
                      <li class="list-bg">
                        <ul class="list-info__list">
                          <li class="w100">
                            <span class="label">Token emission hash</span>
                            <span class="value anim"></span>
                          </li>
                          <li>
                            <span class="label">Token emission version</span>
                            <span class="value anim"></span>
                          </li>
                          <li>
                            <span class="label">Token emission value</span>
                            <span class="value anim anim-delay-1"></span>
                          </li>
                          <li>
                            <span class="label">Token emission ticker</span>
                            <span class="value anim anim-delay-2"></span>
                          </li>
                          <li>
                            <span class="label">Token emission type</span>
                            <span class="value anim anim-delay-3"></span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li>
                        <span class="label">Signs type</span>
                        <span class="value anim"></span>
                      </li>
                      <li>
                        <span class="label">Signs size</span>
                        <span class="value anim anim-delay-1"></span>
                      </li>
                      <li>
                        <span class="label">Signs pkey size</span>
                        <span class="value anim anim-delay-2"></span>
                      </li>
                      <li>
                        <span class="label">Signs pkey type</span>
                        <span class="value anim anim-delay-3"></span>
                      </li>
                      <li class="w100">
                        <span class="label">Signs pkey hash</span>
                        <span class="value anim"></span>
                      </li>
                      <li class="w100">
                        <span class="label">Signs fingerprint</span>
                        <span class="value anim"></span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul v-else class="list-info">
                  <li class="list-bg">
                    <ul class="list-info__list">
                      <li v-if="dagDetails.get(item.hash).version || dagDetails.get(item.hash).version == 0">
                        <span class="label">Version</span>
                        <span class="value">{{ dagDetails.get(item.hash).version }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).round_id || dagDetails.get(item.hash).round_id == 0">
                        <span class="label">Round ID</span>
                        <span class="value">{{ dagDetails.get(item.hash).round_id }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).cell_id">
                        <span class="label">Cell ID</span>
                        <span class="value">{{ dagDetails.get(item.hash).cell_id }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).chain_id">
                        <span class="label">Chain ID</span>
                        <span class="value">{{ dagDetails.get(item.hash).chain_id }}</span>
                      </li>
                    </ul>
                  </li>
                  <li v-if="dagDetails.get(item.hash).datum" class="list-bg">
                    <ul class="list-info__list">
                      <li v-if="dagDetails.get(item.hash).datum.version">
                        <span class="label">Datum version</span>
                        <span class="value">{{ dagDetails.get(item.hash).datum.version }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).datum.type">
                        <span class="label">Datum type</span>
                        <span class="value">{{ dagDetails.get(item.hash).datum.type }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).datum.created">
                        <span class="label">Datum timestamp</span>
                        <span class="value">{{ dagDetails.get(item.hash).datum.created.time }} {{ dagDetails.get(item.hash).datum.created.day }} {{ monthNames[dagDetails.get(item.hash).datum.created.month - 1] }} {{ dagDetails.get(item.hash).datum.created.year }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).datum.size">
                        <span class="label">Datum size</span>
                        <span class="value">{{ dagDetails.get(item.hash).datum.size }}</span>
                      </li>
                      <li v-if="dagDetails.get(item.hash).datum.data.tx" class="w100">
                        <span class="label">Transaction hash</span>
                        <div class="hash__item">
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: dagDetails.get(item.hash).datum.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                            <span>{{ dagDetails.get(item.hash).datum.data.tx.hash }}</span>
                          </router-link>
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: dagDetails.get(item.hash).datum.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                            <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                          </router-link>
                        </div>
                      </li>
                      <li v-if="dagDetails.get(item.hash).datum.data.token_emission" class="list-bg">
                        <ul class="list-info__list">
                          <li>
                            <span class="label">Token emission hash</span>
                            <span class="value">{{ dagDetails.get(item.hash).datum.data.token_emission.addr }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission version</span>
                            <span class="value">{{ dagDetails.get(item.hash).datum.data.token_emission.version }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission value</span>
                            <span class="value">{{ dagDetails.get(item.hash).datum.data.token_emission.value }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission ticker</span>
                            <span class="value">{{ dagDetails.get(item.hash).datum.data.token_emission.ticker }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission type</span>
                            <span class="value">{{ dagDetails.get(item.hash).datum.data.token_emission.type }}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li v-else-if="dagDetails.get(item.hash).datums && dagDetails.get(item.hash).datums.length > 1" class="list-bg">
                    <ul v-for="item in dagDetails.get(item.hash).datums.slice(0, 1)" :key="item.data.tx.hash" class="list-info__list">
                      <li v-if="item.version">
                        <span class="label">Datum version</span>
                        <span class="value">{{ item.version }}</span>
                      </li>
                      <li v-if="item.type">
                        <span class="label">Datum type</span>
                        <span class="value">{{ item.type }}</span>
                      </li>
                      <li v-if="item.created">
                        <span class="label">Datum timestamp</span>
                        <span class="value">{{ item.created.time }} {{ item.created.day }} {{ monthNames[item.created.month - 1] }} {{ item.created.year }}</span>
                      </li>
                      <li v-if="item.size">
                        <span class="label">Datum size</span>
                        <span class="value">{{ item.size }}</span>
                      </li>
                      <li v-if="item.data.tx" class="w100">
                        <span class="label">Transaction hash</span>
                        <div class="hash__item">
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                            <span>{{ item.data.tx.hash }}</span>
                          </router-link>
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                            <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                          </router-link>
                        </div>
                      </li>
                      <li v-if="item.data.token_emission" class="list-bg">
                        <ul class="list-info__list">
                          <li>
                            <span class="label">Token emission hash</span>
                            <span class="value">{{ item.data.token_emission.addr }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission version</span>
                            <span class="value">{{ item.data.token_emission.version }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission value</span>
                            <span class="value">{{ item.data.token_emission.value }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission ticker</span>
                            <span class="value">{{ item.data.token_emission.ticker }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission type</span>
                            <span class="value">{{ item.data.token_emission.type }}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <details class="slice-more">
                      <summary @click="getDagInfo(item.hash)" class="slice-more__title">
                        <span>+{{ dagDetails.get(item.hash).datums.length - 1 }}</span>
                      </summary>
                      <ul v-for="item in dagDetails.get(item.hash).datums.slice(1)" :key="item.data.tx.hash" class="list-info__list">
                        <li v-if="item.version">
                          <span class="label">Datum version</span>
                          <span class="value">{{ item.version }}</span>
                        </li>
                        <li v-if="item.type">
                          <span class="label">Datum type</span>
                          <span class="value">{{ item.type }}</span>
                        </li>
                        <li v-if="item.created">
                          <span class="label">Datum timestamp</span>
                          <span class="value">{{ item.created.time }} {{ item.created.day }} {{ monthNames[item.created.month - 1] }} {{ item.created.year }}</span>
                        </li>
                        <li v-if="item.size">
                          <span class="label">Datum size</span>
                          <span class="value">{{ item.size }}</span>
                        </li>
                        <li v-if="item.data.tx" class="w100">
                          <span class="label">Transaction hash</span>
                          <div class="hash__item">
                            <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                              <span>{{ item.data.tx.hash }}</span>
                            </router-link>
                            <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                              <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                            </router-link>
                          </div>
                        </li>
                        <li v-if="item.data.token_emission" class="list-bg">
                          <ul class="list-info__list">
                            <li>
                              <span class="label">Token emission hash</span>
                              <span class="value">{{ item.data.token_emission.addr }}</span>
                            </li>
                            <li>
                              <span class="label">Token emission version</span>
                              <span class="value">{{ item.data.token_emission.version }}</span>
                            </li>
                            <li>
                              <span class="label">Token emission value</span>
                              <span class="value">{{ item.data.token_emission.value }}</span>
                            </li>
                            <li>
                              <span class="label">Token emission ticker</span>
                              <span class="value">{{ item.data.token_emission.ticker }}</span>
                            </li>
                            <li>
                              <span class="label">Token emission type</span>
                              <span class="value">{{ item.data.token_emission.type }}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </details>
                  </li>
                  <li v-else-if="dagDetails.get(item.hash).datums && dagDetails.get(item.hash).datums.length < 2" class="list-bg">
                    <ul v-for="item in dagDetails.get(item.hash).datums" :key="item.data.tx.hash" class="list-info__list">
                      <li v-if="item.version">
                        <span class="label">Datum version</span>
                        <span class="value">{{ item.version }}</span>
                      </li>
                      <li v-if="item.type">
                        <span class="label">Datum type</span>
                        <span class="value">{{ item.type }}</span>
                      </li>
                      <li v-if="item.created">
                        <span class="label">Datum timestamp</span>
                        <span class="value">{{ item.created.time }} {{ item.created.day }} {{ monthNames[item.created.month - 1] }} {{ item.created.year }}</span>
                      </li>
                      <li v-if="item.size">
                        <span class="label">Datum size</span>
                        <span class="value">{{ item.size }}</span>
                      </li>
                      <li v-if="item.data.tx" class="w100">
                        <span class="label">Transaction hash</span>
                        <div class="hash__item">
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action">
                            <span>{{ item.data.tx.hash }}</span>
                          </router-link>
                          <router-link :to="{ name: 'TransactionDetails', params: { hash: item.data.tx.hash, net: this.selectedDagNet, url: this.dagUrl }}" class="value value--action link--arrow">
                            <img src="@/assets/img/arrow_external-link.svg" title="open in new tab" alt="open in new tab">
                          </router-link>
                        </div>
                      </li>
                      <li v-if="item.data.token_emission" class="list-bg">
                        <ul class="list-info__list">
                          <li>
                            <span class="label">Token emission hash</span>
                            <span class="value">{{ item.data.token_emission.addr }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission version</span>
                            <span class="value">{{ item.data.token_emission.version }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission value</span>
                            <span class="value">{{ item.data.token_emission.value }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission ticker</span>
                            <span class="value">{{ item.data.token_emission.ticker }}</span>
                          </li>
                          <li>
                            <span class="label">Token emission type</span>
                            <span class="value">{{ item.data.token_emission.type }}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li v-if="dagDetails.get(item.hash).data_size">
                    <span class="label">Data size</span>
                    <span class="value">{{ dagDetails.get(item.hash).data_size }}</span>
                  </li>
                  <li v-if="dagDetails.get(item.hash).signs && dagDetails.get(item.hash).signs.length > 1" class="list-bg">
                    <ul v-for="i in dagDetails.get(item.hash).signs.slice(0, 1)" :key="i.type" class="list-info__list">
                      <li>
                        <span class="label">Signs type</span>
                        <span class="value">{{ i.type }}</span>
                      </li>
                      <li>
                        <span class="label">Signs size</span>
                        <span class="value">{{ i.size }}</span>
                      </li>
                      <li v-if="i.pkey.size || i.pkey.size == 0">
                        <span class="label">Signs pkey size</span>
                        <span class="value">{{ i.pkey.size }}</span>
                      </li>
                      <li>
                        <span class="label">Signs pkey type</span>
                        <span class="value">{{ i.pkey.type }}</span>
                      </li>
                      <li>
                        <span class="label">Signs pkey hash</span>
                        <span class="value">{{ i.pkey_hash }}</span>
                      </li>
                      <li>
                        <span class="label">Signs fingerprint</span>
                        <span class="value">{{ i.addr }}</span>
                      </li>
                    </ul>
                    <details class="slice-more">
                      <summary @click="getDagInfo(item.hash)" class="slice-more__title">
                        <span>+{{ dagDetails.get(item.hash).signs.length - 1 }}</span>
                      </summary>
                      <ul v-for="i in dagDetails.get(item.hash).signs.slice(1)" :key="i.type" class="list-info__list">
                        <li>
                          <span class="label">Signs type</span>
                          <span class="value">{{ i.type }}</span>
                        </li>
                        <li>
                          <span class="label">Signs size</span>
                          <span class="value">{{ i.size }}</span>
                        </li>
                        <li v-if="i.pkey.size || i.pkey.size == 0">
                          <span class="label">Signs pkey size</span>
                          <span class="value">{{ i.pkey.size }}</span>
                        </li>
                        <li>
                          <span class="label">Signs pkey type</span>
                          <span class="value">{{ i.pkey.type }}</span>
                        </li>
                        <li>
                          <span class="label">Signs pkey hash</span>
                          <span class="value">{{ i.pkey_hash }}</span>
                        </li>
                        <li>
                          <span class="label">Signs fingerprint</span>
                          <span class="value">{{ i.addr }}</span>
                        </li>
                      </ul>
                    </details>
                  </li>
                  <li v-else-if="dagDetails.get(item.hash).signs && dagDetails.get(item.hash).signs.length < 2" class="list-bg">
                    <ul v-for="i in dagDetails.get(item.hash).signs" :key="i.type" class="list-info__list">
                      <li>
                        <span class="label">Signs type</span>
                        <span class="value">{{ i.type }}</span>
                      </li>
                      <li>
                        <span class="label">Signs size</span>
                        <span class="value">{{ i.size }}</span>
                      </li>
                      <li v-if="i.pkey.size || i.pkey.size == 0">
                        <span class="label">Signs pkey size</span>
                        <span class="value">{{ i.pkey.size }}</span>
                      </li>
                      <li>
                        <span class="label">Signs pkey type</span>
                        <span class="value">{{ i.pkey.type }}</span>
                      </li>
                      <li>
                        <span class="label">Signs pkey hash</span>
                        <span class="value">{{ i.pkey_hash }}</span>
                      </li>
                      <li>
                        <span class="label">Signs fingerprint</span>
                        <span class="value">{{ i.addr }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </details>
            </td>
          </tr>
        </tbody>
        <tbody v-else class="placeholder">
          <tr v-for="item in size" :key="item">
            <td data-label="Timestamp" class="timestamp"><span class="anim"></span></td>
            <td data-label="Hash" class="hash"><span class="anim anim-delay-1"></span></td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevDagPage" :disabled="dagPage.cur === 1" class="pagination__prev"></button>
        <span>Showing {{ dagPage.startItem }} to <span v-if="dagPage.endItem >= dagListCount">{{ dagListCount }}</span><span v-else>{{ dagPage.endItem }}</span> of {{ dagListCount }} entries</span>
        <button @click="nextDagPage" :disabled="dagPage.cur >= dagPage.total" class="pagination__next"></button>
      </div>
    </section>
  </div>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  data() {
    return {
      hintVisible: false,
      hintText: 'Error',

      dagLoading: false,

      dagList: [],
      dagListCount: 0,

      dagListCountAnim: 0,

      dialogDagVisible: false,
      dialogTxVisible: false,
      dialogDagValue: '',
      dialogTxValue: '',

      inputDagValue: '',

      selectedDagNet: 'kelvpn-minkowski',
      netDagOptions: [],

      selectedDagChain: 'support',
      chainDagOptions: [],

      dagDetails: new Map(),
      txDetails: new Map(),

      dagPage: {
        total: 0,
        cur: 1,
        pageLimit: 10,
        startItem: 1,
        endItem: 1,
        itemCount: 100,
      },

      monthNames: [
        'Jan','Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],

      selectedSortDagItem: 'new',
      sortDagOptions: [
        { value: 'new', name: 'first new' },
        { value: 'old', name: 'old ones first' }
      ],

      dagUrl: 'https://engine-minkowski.kelvpn.com/expl',

      nets: new Map([
        ['kelvpn-minkowski', {
          net: { value: 'kelvpn-minkowski', name: 'kelvpn-minkowski'},
          chains: [
            { value: 'support', name: 'support' },
            { value: 'zerochain', name: 'zerochain' },
            { value: 'main', name: 'main' },
          ],
          url: 'https://engine-minkowski.kelvpn.com/expl'
        }],
        // ['subzero', {
        //   net: { value: 'subzero', name: 'subzero'},
        //   chains: [
        //     { value: 'support', name: 'support' },
        //     { value: 'zerochain', name: 'zerochain' },
        //   ],
        //   url: 'https://exp-engine.cellframe.net/expl'
        // }],
        // ['mileena', {
        //   net: { value: 'mileena', name: 'mileena'},
        //   chains: [
        //     { value: 'zerochain', name: 'zerochain' },
        //     { value: 'main', name: 'main' },
        //   ],
        //   url: 'https://engine-mileena.cellframe.net/expl'
        // }],
      ]),
    }
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 100
    }
  },
  methods: {
    dagAnimateCount(animationDuration) {
      const frameDuration = 1000 / 60;
      const totalFrames = Math.round( animationDuration / frameDuration );
      const easeOutQuad = t => 1 - Math.pow(1 - t, 5);

      let frame = 0;
      const countTo = this.dagListCount;

      const counter = setInterval( () => {
        frame++;

        const progress = easeOutQuad( frame / totalFrames );

        const currentCount = Math.round( countTo * progress );

        if ( this.dagListCountAnim !== currentCount ) {
          this.dagListCountAnim = currentCount;
        }

        if ( frame === totalFrames ) {
          clearInterval( counter );
        }
      }, frameDuration );
    },

    async dagItemsCount() {
      try {
        this.dagLoading = true;

        let method = 'dagListCount'
        if (this.selectedDagChain == 'main') method = 'blockListCount'

        const response = await axios.get(this.dagUrl, {
          params: {
            method: method,
            net: this.selectedDagNet,
            chain: this.selectedDagChain,
          }
        });

        this.dagListCount = response.data.count;

        if (response.data.count <= this.size) {
          this.dagPage.itemCount = response.data.count;
        } else {
          this.dagPage.itemCount = this.size
        }

        this.dagAnimateCount(2000)

        console.log(response.data)

        this.dagItemsLimited()

        this.dagPageCount();
        this.dagPaginatedData();
      } catch(error) {
        this.dagLoading = true;
        console.log(error)
        this.hintText = error.message
        this.showHint()
        // let step = 1;
        // // let count = response.data.count / this.dagListCount;
        // let count = 9872;
        // if (count > 1000) {
        //   step = 100
        // } else if (count < 1000 && count > 10) {
        //   step = 10
        // } else {
        //   step = 1
        // }
        // let t = Math.round(1000/(count / step));
        // console.log(step, count, t)
        // let interval = setInterval(() => {
        //   this.dagListCount = this.dagListCount + step;
        //   if (this.dagListCount == count || this.dagListCount > count) {
        //     this.dagListCount = count;
        //     clearInterval(interval);
        //   }
        //   console.log(this.dagListCount)
        // }, t)
      }
    },

    async dagItemsLimited() {
      try {
        let method = 'dagListLimited'
        if (this.selectedDagChain == 'main') method = 'blockListLimited'
        let params = {
          method: method,
          net: this.selectedDagNet,
          chain: this.selectedDagChain,
          count: this.dagPage.itemCount,
          page: this.dagPage.cur,
        }
        if (this.selectedSortDagItem == 'old') params.reverse = 'old';

        setTimeout( async() => {
          const response = await axios.get(this.dagUrl, {
            params: params,
          });

          if (this.selectedDagChain == 'main') {
            console.log(response.data.blocks)
            this.dagList = response.data.blocks;
          } else {
            console.log(response.data.events)
            this.dagList = response.data.events;
          }
          this.dagLoading = false;
        }, 1000)
      } catch(error) {
        this.dagLoading = true;
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    async getDagInfo(hash) {
      if (!this.dagDetails.has(hash)) {
        try {
          let method = 'dag'
          if (this.selectedDagChain == 'main') method = 'block'

          setTimeout( async() => {
            const response = await axios.get(this.dagUrl, {
              params: {
                method: method,
                net: this.selectedDagNet,
                chain: this.selectedDagChain,
                hash: hash
              }
            });
            // let needle_index = this.$refs['hash-ref-'+hash];

            console.log(response.data)
            this.dagDetails.set(hash, response.data);
          }, 1000)
        } catch(error) {
          console.log(error.message)
          this.hintText = error.message
          this.showHint()
        }
      }
    },

    async searchDagItem(hash) {
      try {
        let method = 'dag'
        if (this.selectedDagChain == 'main') method = 'block'

        const response = await axios.get(this.dagUrl, {
          params: {
            method: method,
            net: this.selectedDagNet,
            chain: this.selectedDagChain,
            hash: hash
          }
        });
        // let needle_index = this.$refs['hash-ref-'+hash];

        // this.dagDetails.set(hash, response.data);

        console.log(response.data)
        if (response.data) {
          this.dagDetails.set(hash, response.data);
          this.dialogDagValue = hash
          this.showDagDialog()
        }
      } catch(error) {
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },
    async searchTxItem(hash, net, url) {
      try {
        const response = await axios.get(url, {
          params: {
            method: 'tx',
            net: net,
            hash: hash
          }
        });

        console.log(response.data)
        if (response.data) {
          this.txDetails.set(hash, response.data);
          this.dialogTxValue = hash
          this.showTxDialog()
        }
      } catch(error) {
        console.log(error.message)
        this.hintText = error.message
        this.showHint()
      }
    },

    showDagDialog() {
      this.dialogDagVisible = true;
    },
    showTxDialog() {
      this.dialogTxVisible = true;
    },

    nextDagPage() {
      this.dagPage.cur++;
      if (this.selectedSortDagItem == 'new') {
        this.dagItemsCount();
        this.dagPaginatedData();
      } else if (this.selectedSortDagItem == 'old') {
        this.dagItemsCount();
        this.dagPaginatedData();
      }
    },
    prevDagPage() {
      this.dagPage.cur--;
      if (this.selectedSortDagItem == 'new') {
        this.dagItemsCount();
        this.dagPaginatedData();
      } else if (this.selectedSortDagItem == 'old') {
        this.dagItemsCount();
        this.dagPaginatedData();
      }
    },
    dagPageCount() {
      let l = this.dagListCount,
          s = this.dagPage.itemCount;
      this.dagPage.total = Math.ceil(l/s)
      return this.dagPage.total
    },
    dagPaginatedData() {
      const start = Number((this.dagPage.cur - 1) * this.dagPage.itemCount),
            end = Number(start + this.dagPage.itemCount);
      this.dagPage.startItem = start + 1
      this.dagPage.endItem = end
    },

    showHint() {
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000)
    },
  },

  mounted() {
    for (let entry of this.nets) {
      this.netDagOptions.push(entry[1].net)
    }
    this.chainDagOptions = this.nets.get(this.selectedDagNet).chains
    this.dagItemsCount();
  },

  watch: {
    selectedDagNet() {
      this.dagPage.cur = 1;
      this.selectedSortDagItem = 'new';
      this.chainDagOptions = this.nets.get(this.selectedDagNet).chains
      this.selectedDagChain = this.nets.get(this.selectedDagNet).chains[0].value
      this.dagUrl = this.nets.get(this.selectedDagNet).url
      this.dagItemsCount();
    },
    selectedDagChain() {
      this.dagPage.cur = 1;
      this.selectedSortDagItem = 'new';
      this.dagItemsCount();
    },
    inputDagValue() {
      if (this.dagDetails.has(this.inputDagValue)) {
        this.dialogDagValue = this.inputDagValue
        this.showDagDialog()
      } else if (this.inputDagValue.length > 60) {
        this.searchDagItem(this.inputDagValue)
      }
    },
    selectedSortDagItem() {
      this.dagPage.cur = 1
      if (this.selectedSortDagItem == 'new') {
        this.dagItemsCount();
      } else if (this.selectedSortDagItem == 'old') {
        this.dagItemsCount();
      }
    }
  }
}
</script>

<style scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

.layout {
  margin: 48px 0 0;
  padding: 0 16px 48px;
}


@media screen and (min-width: 768px) {
  .layout h2 {
    display: none;
  }
}
</style>
